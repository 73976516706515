import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-honolulu-hawaii.png'
import image0 from "../../images/cities/scale-model-of-wwii-red-light-district-tour-in-honolulu-hawaii.png"
import image1 from "../../images/cities/scale-model-of-gogo-hawaii-tours-in-honolulu-hawaii.png"
import image2 from "../../images/cities/scale-model-of-eternal-flame-memorial-in-honolulu-hawaii.png"
import image3 from "../../images/cities/scale-model-of-ルイーゼオノの壁画-in-honolulu-hawaii.png"
import image4 from "../../images/cities/scale-model-of-ty-gurney-surf-school-in-honolulu-hawaii.png"
import image5 from "../../images/cities/scale-model-of-waikiki-beach-in-honolulu-hawaii.png"
import image6 from "../../images/cities/scale-model-of-tecventureshawaii-in-honolulu-hawaii.png"
import image7 from "../../images/cities/scale-model-of-overseas-terminal-in-honolulu-hawaii.png"
import image8 from "../../images/cities/scale-model-of-karma-tours-in-honolulu-hawaii.png"
import image9 from "../../images/cities/scale-model-of-duke-paoa-kahanamoku-statue-in-honolulu-hawaii.png"
import image10 from "../../images/cities/scale-model-of-hawaii-surf-adventures-in-honolulu-hawaii.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Honolulu'
            state='Hawaii'
            image={image}
            lat='21.3069444'
            lon='-157.85833330000003'
            attractions={ [{"name": "WWII Red Light District Tour", "vicinity": "1130 Bethel St, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.311024, "lng": -157.8611249}, {"name": "GOGO Hawaii Tours", "vicinity": "307 Lewers St #701, Honolulu", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 21.2802614, "lng": -157.82930929999998}, {"name": "Eternal Flame Memorial", "vicinity": "356-, 420 S Beretania St, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.3080642, "lng": -157.856313}, {"name": "\u30eb\u30a4\u30fc\u30bc\u30aa\u30ce\u306e\u58c1\u753b", "vicinity": "Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2968964, "lng": -157.86048289999997}, {"name": "TY GURNEY SURF SCHOOL", "vicinity": "205 Lewers St, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2783223, "lng": -157.8313758}, {"name": "Waikiki Beach", "vicinity": "Waikiki Beach, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2755521, "lng": -157.825919}, {"name": "Tecventureshawaii", "vicinity": "5180 Likini St #1501, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.34594, "lng": -157.90954699999998}, {"name": "Overseas Terminal", "vicinity": "Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.331976, "lng": -157.9198146}, {"name": "Karma Tours", "vicinity": "3291 Pinaoula St, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2991172, "lng": -157.84117300000003}, {"name": "Duke Paoa Kahanamoku Statue", "vicinity": "Kalakaua Ave, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2755195, "lng": -157.82532379999998}, {"name": "Hawaii Surf Adventures", "vicinity": "2240 K\u016bhi\u014d Ave #3607, Honolulu", "types": ["point_of_interest", "establishment"], "lat": 21.2808199, "lng": -157.82712300000003}] }
            attractionImages={ {"WWII Red Light District Tour":image0,"GOGO Hawaii Tours":image1,"Eternal Flame Memorial":image2,"ルイーゼオノの壁画":image3,"TY GURNEY SURF SCHOOL":image4,"Waikiki Beach":image5,"Tecventureshawaii":image6,"Overseas Terminal":image7,"Karma Tours":image8,"Duke Paoa Kahanamoku Statue":image9,"Hawaii Surf Adventures":image10,} }
       />);
  }
}